import axios from "axios";

axios.defaults.baseURL = `${process.env.NEXT_PUBLIC_SERVER_API_URL}`;
class UserService {
  async createUser(data: any): Promise<any> {
    try {
      const response = await axios.post(
        "/apiv1/users",
        {
          userId: data.userId,
          organizationId: data.organizationId,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getUser(data: { user_id: string; org_id?: string }): Promise<any> {
    const url = data.org_id
      ? `/apiv1/users/${data.user_id}?organizationId=${data.org_id}`
      : `/apiv1/users/${data.user_id}`;

    try {
      const response = await axios.get(url);
      return response.data; // Return just the user
    } catch (error) {
      throw error;
    }
  }

  async getUserProfile(userId: string, organizationId: string): Promise<any> {
    const url = organizationId
      ? `/apiv1/users/${userId}?organizationId=${organizationId}`
      : `/apiv1/users/${userId}`;

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getUserPermissions(data: { user_id: string }): Promise<any> {
    try {
      const response = await axios.post("/apiv1/users/permissions", {
        user_id: data.user_id,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateUser(
    userId: string,
    data: {
      given_name?: string;
      family_name?: string;
      phone_number?: string;
      email?: string;
    }
  ): Promise<any> {
    try {
      // Define a type for the filtered data
      type FilteredData = {
        given_name?: string;
        family_name?: string;
        phone_number?: string;
        email?: string;
      };

      // Filter out empty values
      const filteredData = Object.entries(data).reduce<FilteredData>(
        (acc, [key, value]) => {
          if (value !== null && value !== undefined && value !== "") {
            acc[key as keyof FilteredData] = value;
          }
          return acc;
        },
        {}
      );

      const response = await axios.patch(
        `/apiv1/users/${userId}/profile`,
        filteredData
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data.message || "An error occurred");
      } else {
        throw new Error("An unknown error occurred");
      }
    }
  }

  async requestPasswordReset(email: string): Promise<any> {
    try {
      const response = await axios.post("/apiv1/users/passwordReset", {
        email,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data.message || "An error occurred");
      } else {
        throw new Error("An unknown error occurred");
      }
    }
  }
}

export default new UserService();
