import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import userService from "../services/userService";
// import { useUserStore } from "../stores/userStore";

const useGetUser = () => {
  const QueryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation<any, any, any>(
    {
      mutationFn: async (data) => userService.createUser(data),
      onSuccess: (data: any) => {
        console.log("Mutation Success:", data);
        QueryClient.invalidateQueries({ queryKey: ["userData"] });
      },
      onError: (error) => {
        if (process.env.NODE_ENV === "development") {
          console.error("Mutation Error:", error);
        }
      },
    }
  );
  return {
    getUser: mutateAsync,
    isLoading: isPending,
    isError: isError,
    error: error,
  };
};

const useGetUserProfile = ({
  userId = '',
  organizationId = '',
  enabled,
}: {
  userId?: string;
  organizationId?: string;
  enabled?: boolean;
}) => {
  const { data, error, isPending, isError, isSuccess, refetch, status } =
    useQuery({
      queryKey: ["userProfile"],
      queryFn: async () => userService.getUserProfile(userId, organizationId),
      staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      refetchOnWindowFocus: false, // refetching on window focus can be too aggressive, so it's turned off
      refetchOnReconnect: true, // refetch when the connection is restored
      refetchOnMount: false, // only refetch when parameters change, not every mount
      retry: 1, // retry failed queries once before throwing an error
      retryDelay: (attempt: any) => Math.min(1000 * 2 ** attempt, 30000), // use exponential backoff for retry delays
      enabled: !!userId && !!organizationId && enabled,
    });

  if (error) {
    console.error("Query Error:", error);
  }
  return {
    data: data?.user,
    isLoading: isPending,
    isError: isError,
    error: error,
    isSuccess: isSuccess,
    refetch: refetch,
    status: status,
  };
};

const useUpdateUser = (userId: string) => {
  const QueryClient = useQueryClient();
  // const setUserProfile = useUserStore((state) => state.setUserProfile);

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async (data: any) => userService.updateUser(userId, data),
    onSuccess: (data: any) => {
      console.log("Mutation Success:", data);
      // setUserProfile(data.user);
      QueryClient.invalidateQueries({ queryKey: ["userData", userId] });
      QueryClient.invalidateQueries({ queryKey: ["userProfile"] });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    updateUser: mutateAsync,
    isLoading: isPending,
    isError: isError,
    error: error,
  };
};

const useRequestPasswordReset = (email: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async (data) => userService.requestPasswordReset(email),
    onSuccess: (data: any) => {
      console.log("Mutation Success:", data);
      queryClient.invalidateQueries({ queryKey: ["userData"] });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });

  return {
    requestPasswordReset: mutateAsync,
    isLoading: isPending,
    isError: isError,
    error: error,
  };
};

export {
  useGetUser,
  useGetUserProfile,
  useUpdateUser,
  useRequestPasswordReset,
};
